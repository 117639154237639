.Card-container {
  width: 46%;
  min-width: 280px;
  border-style: solid;
  border-width: 2px;
  border-color: lightgray;
  background-color: white;
  margin-bottom: 50px;
  margin-inline: 10px;
}

.Card-photo {
  height: 200px;
  width: 100%;
  background-color: white;
  object-fit: cover;
  object-position: 0px 0px;
  margin-bottom: -10px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: lightgray;
  background-color: white;
  align-self: center;
}

.Card-icon {
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin-top: 10px;
  border-radius: 5px;
}

.Row-container {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  flex-wrap: wrap;
}

.Card-title-text {
  padding-top: 1px;
  margin-left: 7px;
  color: black;
  font-size: 18px;
  font-family: "Mada";
  margin-top: 18px;
  text-decoration: none;
}

.Card-open-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-bottom: -2px;
  align-self: flex-end;
}

.Card-description-container {
  padding-inline: 10px;
}
