.App {
  text-align: left;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-container {
  max-width: 1000px;
  align-self: center;
  margin-inline: 20px;
  display: flex;
  width: 100%;
  margin-top: 50px;
  flex-direction: column;
  padding-bottom: 40px;
}

.App-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -20px;
}

.App-row-container {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
