.Container {
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Profile-image {
  width: 100px;
  height: 100px;
  border-radius: 99px;
  object-fit: cover;
}

.Title-text {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.Description-text {
  font-size: 17px;
  text-align: center;
  max-width: 300px;
  margin: 0px;
}

.Highlighted-text-small {
  font-size: 15px;
  font-family: "Alegreya Sans";
  color: #fffcf7;
  background-color: tomato;
  text-decoration: none;
  margin-right: 10px;
  margin-left: 10px;
  padding-inline: 2px;
}

.Highlighted-text-small:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
  padding: 0 0.2rem;
  -webkit-animation: colorRotator 30s infinite linear;
  animation: colorRotator 30s infinite linear;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
}

@keyframes colorRotator {
  0%,
  100% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(360deg);
  }
}
