.Section-text {
  color: gray;
  font-size: 14px;
  font-weight: bold;
  font-family: "Muli";
}

.Normal-text {
  color: gray;
  font-size: 14px;
  font-family: "Mada";
  text-decoration: none;
}

.Normal-text-hover:hover {
  background-color: darkgray;
  color: white;
}

.sectionTitle {
  color: gray;
  font-size: 24px;
  font-family: "Mada-bold";
  font-weight: bold;
  width: 100%;
  margin-top: -5px;
  padding-inline: 20px;
}

.List-item {
  margin-bottom: 6px;
}
