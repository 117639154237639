body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Muli-Bold";
  src: local("Muli-Bold"),
    url("./assets/Fonts/muli/Muli-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Mada";
  src: local("Mada"),
    url("./assets/Fonts/Mada/Mada-Regular.ttf") format("truetype");
}

@media screen and (max-width: 911px) {
  #sidebar {
    display: none;
  }
}
