.Header-container {
  display: flex;
  height: 140px;
  justify-content: space-between;
}

.Header-small-container {
  width: 18%;
  height: 100%;
  padding-top: 5px;
  border-top-style: solid;
  border-width: 2px;
  border-color: lightgray;
}

.Header-medium-container {
  width: 37%;
  height: 100%;
  padding-top: 5px;
  border-top-style: solid;
  border-width: 2px;
  border-color: lightgray;
}
